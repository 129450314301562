.headerP{
    font-weight: bold;
    color: rgb(8, 139, 30);
    margin-left: 12%;
}
.cardwone{
   /* // color: black; */
    border: 3px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    padding: 1.5%;
   /*  margin: auto; */
    width: auto;
    height: auto;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
    margin-top: 3%;
    margin-bottom: 500px;
}
.mt{
    margin-top: 50px;
}
.cardwhite{
    border: 3px solid  rgb(255, 255, 255);
    padding: 1.5%;
    width: auto;
    height: 35px;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
   /*  margin-top: 500px; */
}
.cardwtwo{
    border: 3px solid  black;
    padding: 1.5%;
    width: auto;
    height: auto;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
   /*  margin-top: 500px; */
}
.cardpic{
    /* // color: black; */
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    padding: 5px;
    width: 120px;
    height: 120px;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
}

.photoAFC{
    /* // color: black; */
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    padding: 5px;
    width: 200px;
    height: 200px;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
}
.photoAdjust{
   /*  margin-top: -6%; */
    width: 100%;
    height: 200px;
}
.photo-box{
    /* // color: black; */
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    padding: 5px;
    width: 200px;
    height: 2000px;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
}
.pad{
    padding: 20%;
}
.photolabel{
    font-size: large;
    font-weight: bold;
}
.photolabelAFC{
    font-size: x-large;
    font-weight: bold;
}
.aa{
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
}
.aaa{
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    text-align: center;
   /*  //text-align: justify; */
}
.mtminus{
    margin-top: -70px;
}
.minusCardMt{
    margin-top: -0.9%;
}
.lebel-one{
    font-size: small;
    font-weight: bold;
}
.GENERALINFORMATION{
    font-size: medium;
    font-weight: bold;
}
.GiCard{
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    /* padding: 5px; */
    width: auto;
    height: 307px;
    box-sizing: content-box;
}
.surnameCard{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -1px;
}
.surnamelebel{
    font-size: small;
    font-weight: bold;
    margin-left: 35%;
}
.middlenameCard{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    margin-top: -1px;
}
.nicknameCard{
    border: 1px solid  black;
    width: 21.7%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    margin-top: -1px;
}
.presentADD{
    border: 1px solid  black;
    width: 100.2%;
    height: 20px;
    margin-left: -0.9px;
   
   /*  //margin-right: 1%; */
    margin-top: -1%;
}
.presentlebel{
    font-size: small;
    font-weight: bold;
}
.presentlebel2{
    font-size: x-small;
    font-weight: bold;
}
.presentlebel3{
    font-size: xx-small;
    font-weight: bold;
}
.fontcolorwhite{
    color: rgb(255, 255, 255);
    font-size: small;
}
.TELEPHONENUMBER{
    border: 1px solid  black;
    width: 75.4%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.MOBILENUMBER{
    border: 1px solid  black;
    width:21.7%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.dateofbirth{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.placeofbirth{
    border: 1px solid  black;
    width:25%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.citizen{
    border: 1px solid  black;
    width:15.6%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.age{
    border: 1px solid  black;
    width:  100px;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.height{
    border: 1px solid  black;
    width:10.7%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.weight{
    border: 1px solid  black;
    width:10.7%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.sex{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.civilstatus{
    border: 1px solid  black;
    width:25%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.religion{
    border: 1px solid  black;
    width:47%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.specialskill{
    border: 1px solid  black;
    width:50.2%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.languagespoken{
    border: 1px solid  black;
    width:46.9%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.relationship{
    border: 1px solid  black;
    width:25%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.sss{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.philhealth{
    border: 1px solid  black;
    width:25%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.tinnn{
    border: 1px solid  black;
    width:22%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.educationCard{
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    /* padding: 5px; */
    width: auto;
    height: 189px;
    box-sizing: content-box;
    margin-top: -0.7%;
}
.school{
    border: 1px solid  black;
    width: 23%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -1px;
}
.course{
    border: 1px solid  black;
    width:33.2%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -1px;
}
.fromto{
    border: 1px solid  black;
    width:  100px;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -1px;
}
.honor{
    border: 1px solid  black;
    width:21.7%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1px;
}
.collage{
    border: 1px solid  black;
    width: 23%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.DEGREE{
    border: 1px solid  black;
    width:33.2%;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.to{
    border: 1px solid  black;
    width:  100px;
    height: 20px;
    box-sizing: content-box;
    
    margin-top: -10px;
}
.recieved{
    border: 1px solid  black;
    width:21.7%;
    height:20px;
    box-sizing: content-box;
    margin-top: -10px;
}
.EMPLOYMENTRECORDcard{
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    /* padding: 5px; */
    width: auto;
    height: 261px;
    box-sizing: content-box;
    margin-top: -0.7%;
}
.nameofcompany{
    border: 1px solid  black;
    width:21.7%;
    height: 78px;
    box-sizing: content-box;
   
    margin-left: 1.3%;
}
.address{
    border: 1px solid  black;
    width:21.3%;
    height: 78px;
    box-sizing: content-box;
    /* margin-top: -11px; */
   /*  margin-left: 1.3%; */
}
.immediate{
    border: 1px solid  black;
    width:21.8%;
    height: 78px;
    box-sizing: content-box;
    margin-top: -11px;
   
}
.dateemployed{
    border: 2px solid  black;
    width:140px;
    height: 30px;
    box-sizing: content-box;
    margin-top: 15px;
    margin-bottom: -2px;
    margin-left: -1px;
}
.tofromcard{
    border: 2px solid  black;
    width:69px;
    height: 44px;
    box-sizing: content-box;
    /* margin-top: -11px; */
    margin-left: -2px;
}
.tofrom{
    border: 2px solid  black;
    width:69px;
    height: 44px;
    box-sizing: content-box;
   /*  margin-top: -11px; */
    margin-left: 14px;
}
.position{
    border: 1px solid  black;
    width:14.1%;
    height: 78px;
    box-sizing: content-box;
    /* margin-top: -11px; */
    margin-left: 13px;
}
.reasonL{
    border: 1px solid  black;
    width:18%;
    height: 78px;
    box-sizing: content-box;
    margin-top: -11px;
}
.salary{
    border: 1px solid  black;
    width:90px;
    height: 78px;
    box-sizing: content-box;
    margin-top: -11px;
}
.blankCompany{
    border: 1px solid  black;
    width:21.7%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;
    margin-left: 1.3%;
}
.blankAddress{
    border: 1px solid  black;
    width:21.3%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;
}
.blankfrom{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;
}
.blankto{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;
   
}
.blankposition{
    border: 1px solid  black;
    width:14.1%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;
}
.reasonLblank{
    border: 1px solid  black;
    width:17.9%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;
}
.blanksalary{
    border: 1px solid  black;
    width:90px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -1.9%;

}
.blankimmediate{
    border: 1px solid  black;
    width:21.8%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -49px;
   
}

.blankCompanytwo{
    border: 1px solid  black;
    width:21.7%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
    margin-left: 1.3%;
}
.blankCompanytwotwo{
    border: 1px solid  black;
    width:21.7%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
    margin-left: 1.3%;
}
.blankCompanytwotwotwo{
    border: 1px solid  black;
    width:21.7%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
    margin-left: 1.3%;
}
.blankAddresstwo{
    border: 1px solid  black;
    width:11.3%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
}
.blankAddresstwotwo{
    border: 1px solid  black;
    width:11.3%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
}
.blankAddresstwotwotwo{
    border: 1px solid  black;
    width:21.3%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
}
.blankfromtwo{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
   
}
.blanktotwo{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
   
}
.blankfromtwotwo{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
   
}
.blanktotwotwo{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
}

.blankfromtwotwotwo{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
   
}
.blanktotwotwotwo{
    border: 1px solid  black;
    width: 69px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
   
}

.blankpositiontwo{
    border: 1px solid  black;
    width:  89px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
}
.blankpositiontwotwo{
    border: 1px solid  black;
    width:  89px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
}
.blankpositiontwotwotwo{
    border: 1px solid  black;
    width:14.1%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
}
.reasonLblanktwo{
    border: 1px solid  black;
    width:130px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
}
.reasonLblanktwotwo{
    border: 1px solid  black;
    width:130px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
}
.reasonLblanktwotwotwo{
    border: 1px solid  black;
    width:17.9%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
}
.blanksalarytwo{
    border: 1px solid  black;
    width:90px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
}
.blanksalarytwotwo{
    border: 1px solid  black;
    width:90px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
}
.blanksalarytwotwotwo{
    border: 1px solid  black;
    width:90px;
    height: 20px;
    box-sizing: content-box;
    margin-top: -0.9%;
}
.blankimmediatetwo{
    border: 1px solid  black;
    width:21.8%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
}
.blankimmediatetwotwo{
    border: 1px solid  black;
    width:21.8%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -13px;
}
.blankimmediatetwotwotwo{
    border: 1px solid  black;
    width:21.8%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -25px;
}
.familyCard{
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    /* padding: 5px; */
    width: auto;
    height: 200px;
    box-sizing: content-box;
}
.nameFCard{
    border: 1px solid  black;
    width: 35%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -1px;
}
.ageFCard{
    border: 1px solid  black;
    width: 15%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    margin-top: -1px;
}
.companyschoollebel{
    font-size: small;
    font-weight: bold;
    /* margin-left: 35%; */
}
.nameFblankCard{
    border: 1px solid  black;
    width: 35%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.ageFblankCard{
    border: 1px solid  black;
    width: 15%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    margin-top: -10px;
}
.occuationblankCard{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    margin-top: -10px;
}
.companyschoolblankCard{
    border: 1px solid  black;
    width: 21.7%;
    height: 20px;
    box-sizing: content-box;
    margin-top: -10px;
}
.nameOfChildrenCard{
    border: 1px solid  black;
    width: auto;
    height: 43px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
}
.nameOfChildrenCardblank{
    border: 1px solid  black;
    width: auto;
    height: auto;
    box-sizing: content-box;
    
}
.nameFblankCardblank{
    border: 1px solid  black;
    width: 35%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
   /*  margin-top: -10px; */
}
.ageFblankCardw{
    border: 1px solid  black;
    width: 15%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    /* margin-top: -10px; */
}
.occuationblankCardw{
    border: 1px solid  black;
    width: 25%;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    /* margin-top: -10px; */
}
.companyschoolblankCardw{
    border: 1px solid  black;
    width: 21.7%;
    height: 20px;
    box-sizing: content-box;
   /*  margin-top: -10px; */
}
.namefrCard{
    border: 1px solid  black;
    width: 35%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    /* margin-top: -10px; */
}
.namefrgCard{
    border: 1px solid  black;
    width: 28%;
    height: 20px;
    box-sizing: content-box;
   /*  margin-left: 1.3%; */
    /* margin-top: -10px; */
}
.namefrggCard{
    border: 1px solid  black;
    width: 33.8%;
    height: 20px;
    box-sizing: content-box;
   
    /* margin-top: -10px; */
}

.namefrCardw{
    border: 1px solid  black;
    width: 35%;
    height: 20px;
    box-sizing: content-box;
    margin-left: 1.3%;
    margin-top: -10px;
}
.namefrgCardw{
    border: 1px solid  black;
    width: 28%;
    height: 20px;
    box-sizing: content-box;
   /*  margin-left: 1.3%; */
    margin-top: -10px;
}
.namefrggCardw{
    border: 1px solid  black;
    width: 33.8%;
    height: 20px;
    box-sizing: content-box;
   
    margin-top: -10px;
}
.fontsizemedium{
    font-size: medium;
    /* font-weight: bold; */
}
.fontsizesmall{
    font-size: small;
    /* font-weight: bold; */
}

.whiteline{
    border: 1px solid  black;
    width: 1050px;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
   /*  margin-top: -1px; */
}
.signatureLine{
    border: 1px solid  black;
    width: 480px;
    height: 30px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    margin-left: 20%;
   /*  //margin-top: -30px; */
}
.signatureLine2{
   margin-left: 25%;
}
.fullnamelabel{
    font-size: medium;
    font-weight: bold;
    
}
.dateLine{
    border: 1px solid  black;
    width: 200px;
    height: 30px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    /* margin-left: 25%; */
}
.applicantsignature{
    margin-left: 27%;
    margin-top: -1%;
}
.dateee{
    
    margin-top: -1%;
}
.dateeee{
    
    margin-top: -100px;
}
.lostbox{
    border: 2px solid  black;
    width: auto;
    height: auto;
    box-sizing: content-box;
   /*  margin-left: 1.3%; */
}
.examtaken{
    width: 25%;
}
.marginLminus6{
    margin-left: -6%;
}
.marginLminus5{
    margin-left: -6.2%;
}
.mlbb{
    margin-left:  6%;
}
.rawscoreline{
    border: 1px solid  black;
    width: 100px;
    height: 20px;
    box-sizing: content-box;
    /* margin-left: 1.3%; */
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    /* margin-left: 25%; */
}
.DESCRIPTIONline{
    border: 1px solid  black;
    width: 480px;
    height: 20px;
    box-sizing: content-box;
    margin-left: 6%;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
}
.personalitylineone{
    border: 1px solid  black;
    width: 810px;
    height: 20px;
    box-sizing: content-box;
    margin-left: -6%;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
}
.personalitylinetwo{
    border: 1px solid  black;
    width: 810px;
    height: 20px;
    box-sizing: content-box;
    margin-left: 21%;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
}





